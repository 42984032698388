<template>
  <div>
    <h3>Главная</h3>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
}
</script>
